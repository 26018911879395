import { useGoogleLogin } from '@fluentsolar/shared/hooks/useGoogleLogin';
import { GoogleLoginButton } from '@fluentsolar/shared-web/feature-GoogleLoginButton';
import { HeadFont } from '@fluentsolar/shared-web/feature-HeadFont';
import { useApolloState } from '@fluentsolar/shared-web/localHook';
import { Textography } from '@fluentsolar/shared-web/Textography';
import { ASYNC_STATUS } from '@fluentsolar/types';
import { useRouter } from 'next/router';
import * as React from 'react';
import { FluentLogo } from '@fluentsolar/shared-web/feature-FluentLogo/FluentLogo';

const HomePage = () => {
  const [message, setMessage] = React.useState('');
  const { setAllState } = useApolloState();
  const router = useRouter();

  const {
    onGoogleSignIn,
    onGoogleFail,
    googleLoginInfo,
    userInfo,
    ...googleInfo
  } = useGoogleLogin();

  React.useEffect(() => {
    if (googleInfo.status !== ASYNC_STATUS.IDLE) {
      setMessage(googleInfo.message);
    }

    if (googleInfo.status === ASYNC_STATUS.SUCCESS) {
      setAllState({ ...userInfo });
      router?.push('/dashboard/home');
    }
  }, [googleInfo]);

  React.useEffect(() => {
    console.log('router?.query?.reason', router?.query?.reason);
    if (router?.query?.reason) {
      const { reason } = router?.query;

      switch (router?.query?.reason) {
        case 'NO_TOKEN': {
          setMessage('You must log in again.');
          console.log('message', message);
          break;
        }
        case 'PASSWORD_CHANGED': {
          setMessage('Login with new password.');
          break;
        }
        case 'TIMED_OUT': {
          setMessage('Your session timed out. You must Log in again.');
          break;
        }
        default:
          break;
      }
    }
  }, []);
  return (
    <div>
      <div>
        <HeadFont />
        <div
          className="w-full min-h-screen bg-center bg-cover"
          style={{
            backgroundImage:
              'url("https://images.unsplash.com/photo-1565031720429-9b537bcc8d83?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80")',
          }}
        >
          <div className="h-12 sm:h-56" />

          <DivCenter>
            <FluentLogo />
          </DivCenter>
          <div className="h-20" />

          <DivCenter>
            <div className="flex flex-col">
              <div>
                <Textography className="text-white">{message}</Textography>
              </div>
              <div>
                <Textography className="text-white">
                  {googleLoginInfo?.error &&
                    JSON.stringify(googleLoginInfo?.error)}
                </Textography>
              </div>
            </div>
          </DivCenter>
          <div className="h-6" />

          <div className="flex flex-col w-full px-6 m-auto sm:w-4/6 lg:w-1/2 xl:w-1/3 2xl:1/4">
            <DivCenter flexDirection="col">
              <GoogleLoginButton
                googleLogo="/img/google_logo.png"
                onGoogleSignIn={onGoogleSignIn}
                onFail={onGoogleFail}
                text="Sign In with Fluent Solar email"
              />
            </DivCenter>
            <div className="h-2" />
            <DivCenter flexDirection="col">
              <button
                className="w-full h-12 text-white rounded bg-gradient-to-b from-c-approved-100 to-c-approved-200 shadow-file-button hover:shadow-button-hover"
                onClick={() => {
                  router?.push('/login');
                }}
              >
                Sign In Without Fluent Solar email
              </button>
            </DivCenter>
            <div className="h-12" />
            <div className="w-full">
              <div className={`italic text-white text-xs `}>
                No user will: Use the tools, software, or content provided by
                Fluent in excess of the access and/or license rights granted to
                such user Allow other individuals to use the user’s username,
                password, or other credentials to access or use Fluent’s tools,
                software, or content Bypass or attempt to bypass any measures
                taken by Fluent to prevent or restrict access to any tools,
                software, or content
              </div>
              <div className="h-6" />
              <div className={`italic text-white text-xs `}>
                You acknowledge that unauthorized use or access of Fluent’s
                database, software, content, and/or systems could lead to
                criminal liability
              </div>
              <div className="h-6" />
              <div className={`italic text-white text-xs `}>
                FLUENT RESERVES THE RIGHT TO TERMINATE A USER’S ACCESS AND USE
                OF FLUENT’S TOOLS, SOFTWARE, OR CONTENT, REMOVE AND DISCARD ANY
                CONTENT PROVIDED BY THE USER, AND SUSPEND A USER’S ACCESS OR USE
                INDEFINITELY OR FOR AN AMOUNT OF TIME DECIDED BY FLUENT AT ITS
                SOLE DISCRETION FOR ANY REASON INCLUDING, WITHOUT LIMITATION, A
                BREACH OF THE TERMS AND CONDITIONS HEREOF
              </div>
              <div className="h-6" />
              <div className={`italic text-white text-xs `}>
                {process.env.VERCEL_GIT_COMMIT_SHA}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;

interface DivCenterProps {
  flexDirection?: 'row' | 'row-reverse' | 'col' | 'col-reverse';
  children?: React.ReactNode;
}
export const DivCenter: React.FC<DivCenterProps> = (props: DivCenterProps) => {
  return (
    <div
      className={`flex justify-center items-center flex-${props.flexDirection}`}
    >
      {props.children}
    </div>
  );
};

// Trick to full builds
